import { constantRoutes } from '@/router'
import { getRouters } from '@/api/login'
import Layout from '@/layout/index'
import AppMain from '@/layout/components/AppMain'

// 引入不同模块路由
// const path = require('path')
// const files = require.context('@/router', true, /.js$/)
// const moduleRoutes = []
// files.keys().forEach((item) => {
//   const name = path.basename(item, '.js')
//   if (name !== 'index' && name !== 'about') {
//     moduleRoutes.push(files(item).default || files(item))
//   }
// })
// // 根据sort正序排列
// function compare(property) {
//   return function (a, b) {
//     var value1 = a[property]
//     var value2 = b[property]
//     return value1 - value2
//   }
// }
// moduleRoutes.sort(compare('sort'))

const permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        // commit('SET_ROUTES', moduleRoutes)
        // resolve(moduleRoutes)
        // 向后端请求路由数据
        getRouters().then(res => {
          const accessedRoutes = filterAsyncRouter(res.data)
          commit('SET_ROUTES', accessedRoutes)
          resolve(accessedRoutes)
        })
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(route => {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else if (route.component === 'AppMain') {
        route.component = AppMain
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
}

export const loadView = (view) => { // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
