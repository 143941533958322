<template>
  <el-menu
    class="el-menu-demo"
    active-text-color="#006A60"
    :default-active="$route.path"
    :router="true"
  >
    <sub-menu :menu-list="menuList"></sub-menu>
  </el-menu>
</template>

<script>
import SubMenu from './SubMenu'
import { mapGetters } from 'vuex'
export default {
  name: 'SideBar',
  components: {
    SubMenu
  },
  computed: {
    ...mapGetters(['permission_routes'])
  },
  data() {
    return {
      menuList: []
    }
  },
  mounted() {
    this.getRoutes()
  },
  methods: {
    getRoutes() {
      // 过滤出未隐藏的菜单
      this.menuList = this.findSelectItem(this.permission_routes, [])
    },
    // 得到非隐藏的菜单
    findSelectItem(routes) {
      const linkArr = []
      routes.forEach((route) => {
        if (!route.hidden) {
          const children = route.children
          let path = route.path
          if (children && children.length > 0) {
            if (route.redirect && route.redirect != 'noRedirect') {
              path = route.children[0].path
            }
            route.children = this.findSelectItem(children)
          }
          linkArr.push({ ...route, path })
        }
      })
      return linkArr
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-demo {
  position: absolute;
  left: 0;
  top: 72px;
  width: 220px;
  height: calc(100vh - 72px);
  overflow-y: auto;
  border: none;
  // 占位符
  %titleStyle {
    padding-left: 32px !important;
    font-size: 15px;
    color: #666666;
    font-weight: bold;
  }
  ::v-deep .el-submenu {
    .el-submenu__title {
      @extend %titleStyle;
    }
    .el-submenu__title:hover {
      background-color: $lightPrimaryColor;
    }
  }
  ::v-deep .el-menu-item {
    @extend %titleStyle;
    &.is-active,
    &:focus,
    &:hover,
    .el-submenu__title:hover {
      background-color: $lightPrimaryColor;
    }
  }
  ::v-deep .el-submenu {
    .el-menu {
      .el-menu-item {
        padding-left: 60px !important;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
</style>
