<template>
  <el-dialog
    :title="title"
    :visible.sync="show.resetPwd"
    width="600px"
    append-to-body
  >
    <el-form ref="form" :model="user" :rules="rules" label-width="80px">
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input v-model="user.oldPassword" placeholder="请输入旧密码" type="password" />
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="user.newPassword" placeholder="请输入新密码" type="password" />
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input v-model="user.confirmPassword" placeholder="请确认密码" type="password" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="submit">保存</el-button>
        <el-button @click="cancel">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import * as userApi from '@/api/user'

export default {
  name: 'ResetPwd',
  props: {
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      // 表单校验
      rules: {
        oldPassword: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { required: true, validator: equalToPassword, trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    show: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal.resetPwd) {
          this.init()
        }
      }
    }
  },
  methods: {
    init() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.user = {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      }
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          userApi.updateUserPwd(this.user.oldPassword, this.user.newPassword).then((response) => {
            if (response.code === 200) {
              this.$message({ showClose: true, message: '修改成功', type: 'success' })
              this.show.resetPwd = false
            }
          })
        }
      })
    },
    cancel() {
      this.show.resetPwd = false
    }
  }
}
</script>
