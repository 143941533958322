<template>
  <div>
    <div v-for="(menu, i) in menuList" :key="i">
      <el-submenu :index="menu.path" v-if="menu.children && menu.children.length > 0">
        <template slot="title">
          <template v-if="menu.meta.icon">
            <i v-if="menu.meta.icon.startsWith('el')" :class="menu.meta.icon"></i>
            <svg-icon v-else :icon-class="menu.meta.icon" class="svg-icon" />
          </template>
          <span>{{ menu.meta.title }}</span>
        </template>
        <sub-menu
          v-if="menu.children && menu.children.length > 0"
          :menu-list="menu.children"
        ></sub-menu>
        <el-menu-item :index="menu.path" v-else>
          <span slot="title">{{ menu.meta.title }}</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item class="menuItem" :index="menu.path" v-else>
        <template v-if="menu.meta.icon">
          <i v-if="menu.meta.icon.startsWith('el')" :class="menu.meta.icon"></i>
          <svg-icon v-else :icon-class="menu.meta.icon" class="svg-icon" />
        </template>
        <span>{{ menu.meta.title }}</span>
        <span v-if="menu.meta.title === '工作台' && num > 0" class="menuNum">{{ num }}</span>
      </el-menu-item>
    </div>
  </div>
</template>

<script>
import SubMenu from './SubMenu'
import * as workbench from '@/api/workbench'
export default {
  name: 'SubMenu',
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    SubMenu
  },
  data() {
    return {
      num: 0
    }
  },
  mounted() {},
  created() {
    this.getNum()
  },
  methods: {
    //获取待处理数量
    getNum() {
      workbench.num().then((res) => {
        this.num = res.data.num
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  vertical-align: middle !important;
  text-align: center;
  width: 24px !important;
  margin-right: 5px;
}
.menuItem {
  display: inline-block;
  width: 100%;
}
.menuNum {
  display: inline-block;
  margin-left: 30px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 15px;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  // line-height: 30px;
  font-size: 13px;
  background-color: red;
}
</style>
