import request from '@/utils/request'

// 获取待处理数量
export function num(params) {
  return request({
    url: '/web/workbench/num',
    method: 'get',
    params
  })
}

// 订单列表
export function workBenchList(data) {
  return request({
    url: '/web/workbench/order/list',
    method: 'post',
    data
  })
}

