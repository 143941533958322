<template>
  <div class="header-bar">
    <div class="logo"><img src="@/assets/img/header/top-logo.png" alt=""  width="150px"/></div>
    <div class="user-info">
      <el-avatar icon="el-icon-user-solid" :size="36"> </el-avatar>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ this.userInfo && this.userInfo.userName }}
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="resetPwd()">修改密码</el-dropdown-item>
          <!-- <el-dropdown-item @click.native="changeRole()" v-if="userInfo.roles.length > 1">切换用户</el-dropdown-item> -->
          <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <ResetPwd title="修改密码" :show="show"></ResetPwd>
    <change-role-view :showRoleDialog="showRoleDialog" :roles="userInfo.roles"></change-role-view>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import ResetPwd from '@/views/user/components/profile/reset-pwd'
import ChangeRoleView from '@/views/login/components/index/ChangeRoleView'
export default {
  name: 'HeaderBar',
  components: {
    ResetPwd,
    ChangeRoleView
  },
  data() {
    return {
      show: {
        resetPwd: false
      },
      showRoleDialog: {
        dialogVisible: false
      }
    }
  },
  computed: {
    // ...mapGetters(['userInfo']),
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {},
  methods: {
    resetPwd() {
      this.show.resetPwd = true
    },
    logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.reload()
        })
      })
    },
    changeRole() {
      this.showRoleDialog.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.header-bar {
  height: 72px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: $deepPrimaryColor;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .menu-list {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .user-info {
    width: 211px;
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep .el-avatar {
      background: rgba(240, 240, 240, 0.4);
    }
    ::v-deep .el-dropdown {
      color: #fff;
      .el-dropdown-link {
        margin-left: 12px;
        cursor: pointer;
        .el-icon-caret-bottom {
          color: #cccccc;
        }
      }
    }
  }
}
</style>
