<template>
    <router-view />
</template>
<script>
export default {
  name: 'AppMain',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
