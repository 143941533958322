<template>
  <el-dialog
    title="选择登录角色"
    :visible.sync="showRoleDialog.dialogVisible"
    width="300px"
    append-to-body
  >
    <el-radio-group v-model="currentRole">
      <el-radio v-for="item in roles" :key="item" :label="item">{{ item }}</el-radio>
    </el-radio-group>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showRoleDialog.dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleChangeRole">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { changeRole } from '@/api/login'
export default {
  name: 'ChangeRoleView',
  props: {
    showRoleDialog: {
      type: Object,
      default: () => {
        return {
          dialogVisible: false
        }
      }
    },
    roles: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      currentRole: ''
    }
  },
  mounted() {

  },
  methods: {
    // 确定选择用户
    handleChangeRole() {
      changeRole({
        currentRole: this.currentRole
      }).then(res => {
        this.showRoleDialog.dialogVisible = false
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.$emit('handleRouterLink', this.currentRole)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
