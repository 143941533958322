import request from '@/utils/request'

// 登录方法
export function login(username, password) {
  const data = {
    username,
    password
  }
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}
// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/user/getInfo',
    method: 'post'
  })
}

// 获取用户详细信息
export function changeRole(data) {
  return request({
    url: '/user/changeRole',
    method: 'post',
    data
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取路由
export const getRouters = () => {
  return request({
    url: '/user/getRouters',
    method: 'post'
  })
}



// 发送验证码
export function getCode(phone) {
  return request({
    url: '/nocheck/getCode',
    method: 'post',
    data: {
      phone
    }
  })
}

// 校验验证码
export function checkCode(data) {
  return request({
    url: '/nocheck/checkCode',
    method: 'post',
    data
  })
}

// 修改密码
export function updatePwd(data) {
  return request({
    url: '/nocheck/updatePwd',
    method: 'post',
    data
  })
}

// 修改初始密码提示弹窗
export function loginConfirm(data) {
  return request({
    url: '/login/confirm',
    method: 'post',
    data
  })
}