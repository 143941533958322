import Vue from 'vue'
import VueRouter from 'vue-router'
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // console.log(err)
  })
}
Vue.use(VueRouter)

// 公共路由
export const constantRoutes = [
  // { path: '*', redirect: '/404', hidden: true },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/login/Privacy.vue'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    hidden: true,
    children: [
      {
        path: '/login',
        name: 'LoginIndex',
        component: () => import('@/views/login/LoginIndex.vue'),
        hidden: true
      },
      {
        path: '/forget',
        name: 'ForgetPassword',
        component: () => import('@/views/login/ForgetPassword.vue'),
        hidden: true
      },
    ]
  }
]

export default new VueRouter({
  mode: 'hash',
  routes: constantRoutes
})
