import { login, logout, getInfo, changeRole } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    userInfo: {},
    roles: [],
    permissions: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        login(username, password).then(res => {
          setToken(res.data.token)
          commit('SET_TOKEN', res.data.token)
          commit('SET_USERINFO', {})
          localStorage.setItem('userInfo', JSON.stringify(res.data.user))
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(res => {
          const user = res.data
          commit('SET_USERINFO', user)
          commit('SET_PERMISSIONS', user.permissions)
          localStorage.setItem('userInfo', JSON.stringify(user))
          if (user.roles && user.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', user.roles)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          resolve(user)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        resolve(localLogOut(commit))
        logout(state.token)
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        resolve(localLogOut(commit))
      })
    }
  }
}

/**
 * 退出登录
 * 
 * @param {commit方法}} commit 
 */
function localLogOut(commit) {
  commit('SET_TOKEN', '')
  commit('SET_ROLES', [])
  commit('SET_PERMISSIONS', [])
  removeToken()
  localStorage.removeItem('userInfo')
}

export default user
