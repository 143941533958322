import router from './router'
import store from './store'
import { getToken } from '@/utils/auth'

const whiteList = ['/login', '/forget', '/privacy']

router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      // 获取用户信息
      const userInfo = store.getters.userInfo
      if (Object.values(userInfo).length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(res => {
          // 拉取user_info
          const roles = res.roles
          store.dispatch('GenerateRoutes', { roles }).then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            initHome(accessRoutes)
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            next({ ...to }) // hack方法 确保addRoutes已完成
          })
        })
          .catch(err => {
            console.log(err)
          })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    }
    else {
      // 重定向到登录
      next(`/login`)
    }
  }
})
/**
 * 初始化首页
 * 
 * @param {路由信息} accessRoutes 
 */
function initHome(accessRoutes) {
  if (accessRoutes && accessRoutes.length) {
    // 获取第一个地址作为默认首页
    let redirectName = accessRoutes[0]
    if (accessRoutes[0].children) {
      redirectName = accessRoutes[0].children[0].path
    }
    const userInfo = store.getters.userInfo
    if (userInfo.currentRole == '医生') {
      accessRoutes.push({
        path: '/',
        redirect: '/order/create',
        hidden: true
      })
    } else {
      accessRoutes.push({
        path: '/',
        redirect: redirectName,
        hidden: true
      })
    }

  }
}