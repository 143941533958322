import request from '@/utils/request'

// 用户列表
export function list(data) {
  return request({
    url: '/user/list',
    method: 'post',
    data
  })
}

// 用户列表根据用户类型获取
export function listByType(data) {
  return request({
    url: '/user/listByType',
    method: 'post',
    data
  })
}

// 用户详细
export function detail(id) {
  return request({
    url: '/user/detail',
    method: 'post',
    data: { id }
  })
}

// 保存用户
export function save(data) {
  return request({
    url: '/user/save',
    method: 'post',
    data: data
  })
}
// 删除
export function del(id) {
  return request({
    url: '/user/del',
    method: 'post',
    data: { id }
  })
}
// 用户密码重置
export function resetUserPwd(id, password) {
  const data = {
    id,
    password
  }
  return request({
    url: '/user/resetPwd',
    method: 'post',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: 'user/profile/updatePwd',
    method: 'post',
    data
  })
}