<template>
  <div id="home-page">
    <header-bar></header-bar>
    <side-bar></side-bar>
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { HeaderBar, SideBar} from './components'
export default {
  name: "HomePage",
  components: {
    HeaderBar,
    SideBar
  }
};
</script>
<style lang="scss" scoped>
#home-page {
  height: 100vh;
  position: relative;
  .main-content {
    margin-left: 220px !important;
    padding: 30px 20px;
    height: calc(100vh - 72px);
    box-sizing: border-box;
    overflow-y: auto;
    background-color: #F9F9F9;
  }
}
</style>
