import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/element-variables.scss'
import '@/assets/styles/index.scss'
// 引入阿里巴巴图标
import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js'
import './assets/icons' // icon

// 菜单权限
import './permission'
import permission from './directive/permission'
Vue.use(permission)

// 分页
import Pagination from '@/components/Pagination'
Vue.component('Pagination', Pagination)

Vue.use(ElementUI)

Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false

// 修改 el-dialog 默认点击遮照为不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
